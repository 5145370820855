<template>
  <div>
    <b-tabs
      vertical
      pills
    >
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Usuário</span>
        </template>
        <Usuario />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Seguradoras</span>
        </template>
        <Seguradora />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Revendas</span>
        </template>
        <b-card>
          <Revendas />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Marcas</span>
        </template>
        <b-card>
          <Marcas />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Linhas</span>
        </template>
        <b-card>
          <Linhas />
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Técnicos</span>
        </template>
        <b-card>
          <Tecnicos />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import Usuario from './tabs/Usuario.vue'
import Seguradora from './tabs/Seguradora.vue'
import Revendas from './tabs/Revendas.vue'
import Marcas from './tabs/Marcas.vue'
import Linhas from './tabs/Linhas.vue'
import Tecnicos from './tabs/Tecnicos.vue'

export default {
  components: {
    Usuario,
    Seguradora,
    Revendas,
    Marcas,
    Linhas,
    Tecnicos,
    BTabs,
    BTab,
    BCard,
  },
}
</script>
