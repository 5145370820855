<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="openModalUser"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Usuário
        </b-button>
      </b-col>
    </b-row>
    <b-card title="Usuários">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row
          class="mb-2"
          align-h="between"
        >
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
        </b-row>

        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="items"
          :filter="searchQuery"
        >
          <template #cell(status)="data">
            <b-badge :variant="data.item.status === 1 ? 'success' : 'danger'">
              {{ data.item.status === 1 ? 'ATIVO' : 'INATIVO' }}
            </b-badge>
          </template>
          <template #cell(cpf_cnpj)="data">
            {{ cpfCNPJ(data.item.cpf_cnpj) }}
          </template>
          <template #cell(telefone)="data">
            <div v-if="data.item.telefone">
              {{ maskFone(data.item.telefone) }}
            </div>

          </template>
          <template #cell(opcoes)="data">
            <b-row>
              <b-col cols="auto">
                <feather-icon
                  icon="EditIcon"
                  class="color-icon"
                  @click="detailUser(data.item)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.left="`${data.item.status === 1 ? 'INATIVAR USUÁRIO' : 'ATIVAR USUÁRIO'}`"
                  icon="UserXIcon"
                  class="trash-icon"
                  @click="disableUser(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      ref="userModal"
      hide-footer
      no-close-on-backdrop
      @hide="clearModal"
    >
      <b-row class="mb-2">
        <b-col
          cols="12"
          class="mt-1"
        >
          Nome:
          <b-form-input
            v-model="cad.name"
            placeholder="Nome"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          CPF/CNPJ:
          <cleave
            v-if="cad.cpf_cnpj.length > 14"
            v-model="cad.cpf_cnpj"
            class="form-control"
            :class="{ 'is-invalid': !isValidCpfOrCnpj && !isValidLength }"
            :raw="false"
            :options="{
              numericOnly: true, ...mask.cnpj
            }"
            placeholder="CPF/CNPJ"
          />
          <cleave
            v-else
            v-model="cad.cpf_cnpj"
            class="form-control"
            :class="{ 'is-invalid': !isValidCpfOrCnpj && !isValidLength }"
            :raw="false"
            :options="{
              numericOnly: true, ...mask.cpf
            }"
            placeholder="CPF/CNPJ"
          />
          <div
            v-if="cad.cpf_cnpj.length < 11 || cad.cpf_cnpj.length > 12 || cad.cpf_cnpj.length < 14"
            class="invalid-feedback"
          >
            CPF/CNPJ inválido
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Email:
          <b-form-input
            v-model="cad.email"
            placeholder="Email"
            :disabled="!isValidCpfOrCnpj"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Telefone:
          <b-form-input
            v-model="cad.telefone"
            placeholder="Telefone"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Senha:
          <b-form-input
            v-model="cad.password"
            placeholder="********"
            :disabled="!isValidCpfOrCnpj"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Função:
          <v-select
            v-model="selectedFuncao"
            placeholder="Função"
            :options="optionsFuncao"
            :disabled="!isValidCpfOrCnpj"
          />
        </b-col>
        <b-col
          v-if="selectedFuncao && selectedFuncao.id === 9"
        >
          <label>Selecione a seguradora</label>
          <v-select
            v-model="selectedSeguradora"
            :options="optionsSeguradora"
            :clearable="false"
            class="mb-2 item-selector-title"
            placeholder="Selecionar Seguradora"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="end">
        <b-col
          v-if="!editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="storeUser"
          >
            Cadastrar
          </b-button>

        </b-col>
        <b-col
          v-if="editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateUser"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormInput,
  BModal,
  BBadge,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormInput,
    BModal,
    BBadge,
    vSelect,
    Cleave,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchQuery: null,
      editModal: false,
      userID: '',
      cad: {
        name: '',
        email: '',
        cpf_cnpj: '',
        telefone: '',
        password: '',
        empresa_id: '',
        perfil_id: '',
        funcao_id: '',
      },
      fields: [
        { key: 'name', label: 'nome', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF/CNPJ', class: 'text-center' },
        { key: 'funcao', label: 'função', class: 'text-center' },
        { key: 'email', label: 'email', class: 'text-center' },
        { key: 'telefone', label: 'telefone', class: 'text-center' },
        { key: 'status', label: 'status', class: 'text-center' },
        { key: 'opcoes', label: 'opções', class: 'text-center' },
      ],
      mask: {
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
      },
      items: [],
      empresaID: null,
      optionsFuncao: [],
      selectedFuncao: [],
      load: true,
      optionsSeguradora: [],
      selectedSeguradora: [],
    }
  },
  computed: {
    isValidCpfOrCnpj() {
      const cpfCnpjLength = this.cad.cpf_cnpj.replace(/[^0-9]/g, '').length
      return cpfCnpjLength === 11 || cpfCnpjLength === 14
    },
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getUsers()
  },
  methods: {
    clearModal() {
      this.cad = {
        name: '',
        email: '',
        cpf_cnpj: '',
        telefone: '',
        password: '',
        perfil_id: '',
        funcao_id: '',
      }
      this.selectedFuncao = []
      this.editModal = false
      this.selectedSeguradora = []
    },
    updateUser() {
      this.load = true
      this.cad.perfil_id = this.selectedFuncao.id
      this.cad.funcao_id = this.selectedFuncao.id
      this.cad.empresa_id = this.empresaID
      this.cad.cpf_cnpj = this.soNumero(this.cad.cpf_cnpj)
      this.cad.telefone = this.cad.telefone ? this.soNumero(this.cad.telefone) : null
      axios.put(`api/users/update/${this.userID}`, this.cad, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$refs.userModal.hide()
        this.getUsers()
        this.$swal({
          title: 'Usuário atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: `Ocorreu um erro: ${error.response.data.mensagem}`,
          })
        })
    },
    async detailUser(item) {
      this.load = true
      await this.getFuncoes()
      axios.get(`api/users/edit/${item.id}`).then(res => {
        const dt = res.data.dados
        this.cad = {
          name: dt.name,
          email: dt.email,
          cpf_cnpj: dt.cpf_cnpj,
          telefone: dt.telefone,
          password: dt.password,
          empresa_id: dt.empresa_id,
          perfil_id: dt.perfil_id,
          funcao_id: dt.funcao_id,
        }
        this.userID = item.id
        this.editModal = true
        this.load = false
        this.$refs.userModal.show()
      })
    },
    disableUser(item) {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.load = true
          const body = {
            status: item.status !== 1,
          }
          axios.put(`api/users/status/${item.id}`, body, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(() => {
            this.getUsers()
            this.$swal({
              title: 'Ação realizada com sucesso!',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(error => {
              this.load = false
              this.$swal({
                title: 'ERRO',
                icon: 'error',
                text: `Ocorreu um erro: ${error.response.data.mensagem}`,
              })
            })
        }
      })
    },
    storeUser() {
      this.load = true
      this.cad.perfil_id = this.selectedFuncao.id
      this.cad.funcao_id = this.selectedFuncao.id
      this.cad.empresa_id = this.empresaID
      axios.post('api/users/store', {
        name: this.cad.name,
        email: this.cad.email,
        cpf_cnpj: this.soNumero(this.cad.cpf_cnpj),
        telefone: this.cad.telefone ? this.soNumero(this.cad.telefone) : null,
        password: this.cad.password,
        empresa_id: this.cad.empresa_id,
        perfil_id: this.cad.perfil_id,
        funcao_id: this.cad.funcao_id,
        seguradora_id: this.selectedSeguradora ? this.selectedSeguradora.id : null,
      }).then(() => {
        this.$refs.userModal.hide()
        this.getUsers()
        this.$swal({
          title: 'Usuário Cadastrado!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante o cadastro: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    getUsers() {
      this.items = []
      axios.get(`api/empresas/usuarios/${this.empresaID}`).then(res => {
        this.load = false
        this.items = res.data.dados
      })
    },
    async openModalUser() {
      await this.getFuncoes()
      await this.getSeguradoras()
      this.$refs.userModal.show()
    },
    getFuncoes() {
      this.optionsFuncao = []
      axios.get('api/funcao/')
        .then(res => {
          res.data.dados.map(item => {
            if (item.id !== 2) {
              this.optionsFuncao.push({
                label: item.nome,
                id: item.id,
              })
            }
          })
        })
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
    getSeguradoras() {
      this.optionsSeguradora = []
      axios.get(`api/empresas/seguradoras/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsSeguradora.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    maskFone(number) {
      let phone = number.replace(/\D/g, '')
      const match = phone.match(/^(\d{1,2})(\d{0,5})(\d{0,4})$/)
      if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}${match[4] ? '' : ''}`
      }
      return phone
    },
  },
}
</script>
