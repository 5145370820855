<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="cadastrarNovoTecnico()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Téc./Prestador
        </b-button>
      </b-col>
    </b-row>
    <b-card
      title="Técnicos"
    >
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row
          class="mb-2"
          align-h="between"
        >
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
        </b-row>
        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="items"
          :filter="searchQuery"
        >
          <template #cell(status)="data">
            <b-badge :variant="data.item.status === 1 ? 'success' : 'danger'">
              {{ data.item.status === 1 ? 'ATIVO' : 'INATIVO' }}
            </b-badge>
          </template>
          <template #cell(cpf_cnpj)="data">
            {{ cpfCNPJ(data.item.cpf_cnpj) }}
          </template>
          <template #cell(opcoes)="data">
            <b-row>
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.left="'EDITAR'"
                  icon="EditIcon"
                  class="color-icon cursor-pointer"
                  @click="detailTecnicos(data.item)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  v-b-tooltip.hover.left="`${data.item.status === 1 ? 'INATIVAR TÉCNICO' : 'ATIVAR TÉCNICO'}`"
                  icon="UserXIcon"
                  class="color-icon cursor-pointer"
                  @click="disableUser(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      ref="tecnicoModal"
      hide-footer
      no-close-on-backdrop
      @hide="clearModal"
    >
      <b-form>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*Nome"
              label-for="name"
            >
              <b-form-input
                id="customer-name"
                v-model="nome"
                trim
                placeholder="Nome"
              />
              <!-- <span class="text-danger">{{ errorState.error.errorName.message }}</span> -->
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Favorecido"
              label-for="favorecido"
            >
              <b-form-input
                id="customer-name"
                v-model="favorecido"
                trim
                placeholder="Favorecido"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*CPF/CNPJ"
              label-for="CPF/CNPJ"
            >
              <cleave
                v-if="cpf_cnpj.length > 11"
                id="customDelimiter"
                v-model="cpf_cnpj"
                class="form-control "
                :raw="true"
                :options="{
                  numericOnly: true, ...mask.cnpj
                }"
                placeholder="CPF/CNPJ"
              />
              <cleave
                v-else
                id="customDelimiter"
                v-model="cpf_cnpj"
                class="form-control"
                :raw="true"
                :options="{
                  numericOnly: true, ...mask.cpf
                }"
                placeholder="CPF/CNPJ"
              />
              <!-- <span class="text-danger">{{ errorState.error.errorCpfCnpj.message }}</span> -->
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*Banco"
              label-for="banco"
            >
              <b-form-input
                id="banco"
                v-model="banco"
                trim
                placeholder="Banco"
              />
              <!-- <span class="text-danger">{{ errorState.error.errorBanco.message }}</span> -->
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Agência"
              label-for="agencia"
            >
              <b-form-input
                id="Agência"
                v-model="agencia"
                trim
                placeholder="Agência"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Conta"
              label-for="conta"
            >
              <b-form-input
                id="Conta"
                v-model="conta"
                trim
                placeholder="Conta"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="PIX"
              label-for="chave_pix"
            >
              <b-form-input
                id="chave_pix"
                v-model="chave_pix"
                trim
                placeholder="PIX"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Endereco"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="endereco"
                placeholder="Endereço"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*CEP"
            >
              <b-input-group>
                <cleave
                  v-model="cep"
                  name="cep"
                  class="form-control"
                  :raw="false"
                  :options="{numericOnly: true, ...mask.cep}"
                  placeholder="00000-000"
                  @keyup.enter="consultaCEP()"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="consultaCEP()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <!-- <span class="text-danger">{{ errorState.error.errorCep.message }}</span> -->
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Numero"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="numero"
                type="number"
                placeholder="Número"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="UF"
              label-for="endereco"
            >
              <b-form-input
                id="endereco"
                v-model="uf"
                placeholder="UF"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="*BAIRRO"
            >
              <b-form-input
                v-model="bairro"
                placeholder="Bairro"
                trim
              />
              <!-- <span class="text-danger">{{ errorState.error.errorBairro .message }}</span> -->
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Cidade"
              label-for="country"
            >
              <b-form-input
                id="endereco"
                v-model="cidade"
                placeholder="Cidade"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Telefone"
              label-for="telefone"
            >
              <b-form-input
                id="telefone"
                v-model="telefone"
                placeholder="Telefone"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Email"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <b-form-group
              label="Assistência"
              label-for="Assistência"
            >
              <b-form-input
                id="assistencia"
                v-model="assistencia"
                placeholder="Assistência"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label>Linhas</label>
            <v-select
              v-model="selectedLinhas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsLinhas"
              :clearable="false"
              class="mb-2 item-selector-title"
              placeholder="Selecionar Linhas"
              multiple
              :close-on-select="false"
            />
          </b-col>
          <b-col>
            <label>Cidades</label>
            <v-select
              v-model="selectedCidades"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsCidades"
              :clearable="false"
              class="mb-2 item-selector-title"
              placeholder="Selecionar cidades"
              multiple
              :close-on-select="false"
            />
          </b-col>
        </b-row>
      </b-form>
      <hr>
      <b-row align-h="end">
        <b-col
          v-if="!editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="storeNovoTecnico"
          >
            Cadastrar
          </b-button>

        </b-col>
        <b-col
          v-if="editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateTecnico"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormInput,
  BModal,
  BBadge,
  BFormGroup,
  BInputGroup,
  BForm,
  BInputGroupAppend,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormInput,
    BModal,
    BBadge,
    BFormGroup,
    BInputGroup,
    BForm,
    BInputGroupAppend,
    Cleave,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      searchQuery: null,
      editModal: false,
      tecnicoID: '',
      nome: '',
      endereco: '',
      cep: '',
      bairro: '',
      cidade: '',
      telefone: '',
      email: '',
      uf: '',
      numero: '',
      cpf_cnpj: '',
      chave_pix: '',
      banco: '',
      conta: '',
      agencia: '',
      favorecido: '',
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 3],
          uppercase: true,
        },
      },
      fields: [
        { key: 'nome', label: 'nome', class: 'text-center' },
        // { key: 'favorecido', label: 'Favorecido', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF/CNPJ', class: 'text-center' },
        // { key: 'banco', label: 'Banco', class: 'text-center' },
        // { key: 'agencia', label: 'Agência', class: 'text-center' },
        // { key: 'conta', label: 'Conta', class: 'text-center' },
        // { key: 'chave_pix', label: 'PIX', class: 'text-center' },
        // { key: 'endereco', label: 'Endereço', class: 'text-center' },
        // { key: 'cep', label: 'CEP', class: 'text-center' },
        // { key: 'numero', label: 'Numero', class: 'text-center' },
        // { key: 'uf', label: 'UF', class: 'text-center' },
        // { key: 'bairro', label: 'BAIRRO', class: 'text-center' },
        // { key: 'cidade', label: 'Cidade', class: 'text-center' },
        // { key: 'email', label: 'Email', class: 'text-center' },
        { key: 'status', label: 'status', class: 'text-center' },
        { key: 'opcoes', label: 'opções' },
      ],
      //   mask: {
      //     cpf: {
      //       delimiters: ['.', '.', '-'],
      //       blocks: [3, 3, 3, 2],
      //       uppercase: true,
      //     },
      //   },
      items: [],
      empresaID: null,
      optionsFuncao: [],
      selectedFuncao: [],
      load: true,
      assistencia: '',
      optionsLinhas: [],
      selectedLinhas: [],
      optionsCidades: [],
      selectedCidades: [],
    }
  },
  computed: {
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getTecnicos()
  },
  methods: {
    errorState() {
      // eslint-disable-next-line no-unused-vars
      const { nome, cpf_cnpj, banco, cep, bairro } = this
      const { checkTax, checkName } = this.checkErrorState()

      const checkedTax = { status: checkTax(cpf_cnpj), label: 'CPF OU CNPJ' }
      const checkedName = { status: checkName(nome), label: 'NOME' }
      const checkedBanco = { status: banco.length > 0, label: 'BANCO' }
      const checkedCep = { status: this.validarCep(cep), label: 'CEP  ' }
      const checkedBairro = { status: bairro.length > 0, label: 'BAIRRO' }

      const status = checkedTax.status && checkedName.status && checkedBanco.status && checkedCep.status && checkedBairro.status

      const error = [checkedTax, checkedName, checkedBanco, checkedCep, checkedBairro].filter(e => !e.status)

      return { status, error }
    },
    validarCPF(cpf) {
      if (cpf.length !== 11 || /^(\d)\1+$/g.test(cpf)) {
        return false
      }

      let sum = 0
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i)
      }

      let remainder = 11 - (sum % 11)
      if (remainder === 10 || remainder === 11) {
        remainder = 0
      }

      if (remainder !== parseInt(cpf.charAt(9))) {
        return false
      }

      sum = 0
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i)
      }

      remainder = 11 - (sum % 11)
      if (remainder === 10 || remainder === 11) {
        remainder = 0
      }

      return remainder === parseInt(cpf.charAt(10))
    },
    validarCNPJ(cnpj) {
      if (cnpj.length !== 14 || /^(\d)\1+$/g.test(cnpj)) {
        return false
      }

      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho)
      const digitos = cnpj.substring(tamanho)
      let soma = 0
      let pos = tamanho - 7

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--
        if (pos < 2) {
          pos = 9
        }
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

      if (resultado !== parseInt(digitos.charAt(0))) {
        return false
      }

      tamanho += 1
      numeros = cnpj.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7

      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--
        if (pos < 2) {
          pos = 9
        }
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

      return resultado === parseInt(digitos.charAt(1))
    },
    validarCep(cep) {
      cep = cep.replace(/\D/g, '') // Remove caracteres não numéricos

      if (cep.length !== 8 || !/^\d{8}$/.test(cep)) {
        return false
      }

      return true
    },
    checkErrorState() {
      const checkName = nome => {
        if (nome.length < 5) {
          return false
        }
        return true
      }

      const checkTax = tax => {
        tax = tax.replace(/[^\d]+/g, '')
        // CPF
        if (tax.length <= 11) {
          return this.validarCPF(tax)
        }

        // CNPJ
        return this.validarCNPJ(tax)
      }

      return { checkName, checkTax }
    },
    clearModal() {
      this.nome = ''
      this.endereco = ''
      this.cep = ''
      this.bairro = ''
      this.cidade = ''
      this.telefone = ''
      this.email = ''
      this.uf = ''
      this.numero = ''
      this.cpf_cnpj = ''
      this.favorecido = ''
      this.assistencia = ''
      this.selectedLinhas = []
      this.selectedCidades = []
      this.editModal = false
      this.banco = ''
      this.agencia = ''
      this.pix = ''
    },
    // eslint-disable-next-line consistent-return
    updateTecnico() {
      const { status, error: errorForm } = this.errorState()
      if (!status) {
        return this.$swal({
          title: 'ERRO',
          html: `
            <strong>Campos incompletos </strong><br />
            <span>
              ${errorForm.map(err => `<strong>-</strong> ${err.label}</br>`)}
            </span>
          `,
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      }
      this.load = true
      this.cpf_cnpj = this.soNumero(this.cpf_cnpj)
      const body = {
        nome: this.nome,
        email: this.email,
        cpf_cnpj: this.cpf_cnpj,
        cep: this.cep ? this.soNumero(this.cep) : '',
        endereco: this.endereco,
        bairro: this.bairro,
        cidade: this.cidade,
        telefone: this.telefone ? this.soNumero(this.telefone) : '',
        uf: this.uf,
        numero: this.numero,
        chave_pix: this.chave_pix,
        banco: this.banco,
        conta: this.conta,
        agencia: this.agencia,
        favorecido: this.favorecido,
      }
      body.linhas_prestador = this.selectedLinhas.map(item => item.id)
      body.cidades_prestador = this.selectedCidades.map(item => item.id)
      axios.put(`api/prestador_servico/update/${this.tecnicoID}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$refs.tecnicoModal.hide()
        this.getTecnicos()
        this.clearModal()
        this.$swal({
          title: 'Prestador atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: `Ocorreu um erro: ${error.response.data.mensagem}`,
          })
        })
    },
    async detailTecnicos(item) {
      await this.getLinhas()
      axios.get(`api/prestador_servico/edit/${item.id}`).then(res => {
        const dt = res.data.dados
        this.nome = dt.nome
        this.email = dt.email
        this.cpf_cnpj = dt.cpf_cnpj
        this.cep = dt.cep
        this.endereco = dt.endereco
        this.bairro = dt.bairro
        this.cidade = dt.cidade
        this.telefone = dt.telefone
        this.email = dt.email
        this.uf = dt.uf
        this.numero = dt.numero
        this.chave_pix = dt.chave_pix
        this.banco = dt.banco
        this.conta = dt.conta
        this.agencia = dt.agencia
        this.favorecido = dt.favorecido
        this.favorecido = dt.favorecido
        this.empresa_id = dt.empresa_id
        this.editModal = true
        if (dt.linhas !== null) {
          dt.linhas.map(dados => {
            this.selectedLinhas.push({
              id: dados.id,
              label: dados.nome,
            })
          })
        }
        if (dt.cidades !== null) {
          dt.cidades.map(dados => {
            this.selectedCidades.push({
              id: dados.id,
              label: dados.nome,
            })
          })
        }
        this.$refs.tecnicoModal.show()
        this.tecnicoID = item.id
      })
    },
    disableUser(item) {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.load = true
          const body = {
            status: item.status !== 1,
          }
          axios.put(`api/prestador_servico/status/${item.id}`, body).then(() => {
            this.getTecnicos()
            this.$swal({
              title: 'Ação Realizada com sucesso!',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(error => {
              this.load = false
              this.$swal({
                title: 'ERRO',
                icon: 'error',
                text: `Ocorreu um erro: ${error.response.data.mensagem}`,
              })
            })
        }
      })
    },
    // eslint-disable-next-line consistent-return
    storeNovoTecnico() {
      const { status, error: errorForm } = this.errorState()
      if (!status) {
        return this.$swal({
          title: 'ERRO',
          html: `
            <strong>Campos incompletos </strong><br />
            <span>
              ${errorForm.map(err => `<strong>-</strong> ${err.label}</br>`)}
            </span>
          `,
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      }
      if (!this.nome || !this.cpf_cnpj) {
        this.$swal({
          title: 'Erro ao cadastrar um prestador!',
          text: 'Campos Obrigatórios não informado: nome e cpf',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.load = true
      const body = {
        empresa_id: this.empresaID,
        nome: this.nome,
        cpf_cnpj: this.soNumero(this.cpf_cnpj),
        banco: this.banco,
        agencia: this.agencia,
        conta: this.conta,
        chave_pix: this.chave_pix,
      }
      this.endereco.length ? body.endereco = this.endereco : null
      this.cep.length ? body.cep = this.soNumero(this.cep) : null
      this.bairro.length ? body.bairro = this.bairro : null
      this.cidade.length ? body.cidade = this.cidade : null
      this.telefone.length ? body.telefone = this.soNumero(this.telefone) : null
      this.email.length ? body.email = this.email : null
      this.uf.length ? body.uf = this.uf : null
      this.numero.length ? body.numero = this.numero : null
      this.favorecido.length ? body.favorecido = this.favorecido : null
      this.assistencia.length ? body.assistencia = this.assistencia : null
      this.selectedLinhas.length > 0 ? body.linhas_prestador = this.selectedLinhas.map(item => item.id) : null
      this.selectedCidades.length > 0 ? body.cidades_prestador = this.selectedCidades.map(item => item.id) : null

      axios
        .post('api/prestador_servico/store', body)
        .then(() => {
          this.$refs.tecnicoModal.hide()
          this.getPrestadores()
          this.clearModal()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Técnico cadastrado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.$swal({
              title: 'Erro ao cadastrar um prestador!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
    async consultaCEP() {
      if (this.cep) {
        if (this.cep.replace(/[^0-9]/g, '').length === 8) {
          await axios
            .get(`https://viacep.com.br/ws/${this.cep}/json/`)
            .then(async res => {
              const { data } = res
              if (data.erro) {
                this.$swal({
                  title: 'ERROR',
                  text: 'CEP não encontrado',
                  icon: 'error',
                  customClass: {
                    comfirmButton: 'btn btn-danger',
                  },
                })
              } else {
                this.endereco = data.logradouro
                this.bairro = data.bairro
                this.cidade = data.localidade
                this.uf = data.uf
              }
            })
            .catch(() => {
              this.$swal({
                title: 'ERROR',
                text: 'CEP não encontrado',
                icon: 'error',
                customClass: {
                  comfirmButton: 'btn btn-danger',
                },
              })
            })
        }
      }
      return null
    },
    getTecnicos() {
      this.items = []
      axios.get(`api/prestador_servico/${this.empresaID}`).then(res => {
        this.items = res.data.dados
        this.load = false
      })
    },
    async openModalTecnico() {
      await this.getFuncoes()
      this.$refs.tecnicoModal.show()
    },
    async cadastrarNovoTecnico() {
      await this.getLinhas()
      await this.getCidadesList()
      this.$refs.tecnicoModal.show()
    },
    getFuncoes() {
      this.optionsFuncao = []
      axios.get('api/funcao/')
        .then(res => {
          res.data.dados.map(item => {
            if (item.id !== 2) {
              this.optionsFuncao.push({
                label: item.nome,
                id: item.id,
              })
            }
          })
        })
    },
    soNumero(valor) {
      return valor.replace(/\D/g, '')
    },
    getLinhas() {
      this.optionsLinhas = []
      axios.get(`api/empresas/linhas/${this.empresaID}`).then(res => {
        res.data.dados.map(item => {
          this.optionsLinhas.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    getCidadesList() {
      this.optionsCidades = []
      axios.get('api/localidade/cidades').then(res => {
        res.data.dados.map(item => {
          this.optionsCidades.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
  },
}
</script>
