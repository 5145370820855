<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="openModalMarcas"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Marcas
        </b-button>
      </b-col>
    </b-row>
    <b-card title="Marcas">
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-row
          class="mb-2"
          align-h="between"
        >
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
        </b-row>
        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="items"
          :filter="searchQuery"
        >
          <template #cell(opcoes)="data">
            <b-row>
              <b-col cols="auto">
                <feather-icon
                  icon="EditIcon"
                  class="color-icon"
                  @click="detailMarcas(data.item)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  icon="Trash2Icon"
                  class="trash-icon"
                  @click="disableMarcas(data.item)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      ref="marcasModal"
      hide-footer
      title="Cadastrar Marcas"
      no-close-on-backdrop
      @hide="clearModal"
    >
      <b-row class="mb-2">
        <b-col
          cols="12"
          class="mt-1"
        >
          Marcas:
          <v-select
            v-model="selectedMarca"
            :options="optionsMarcas"
            multiple
            :close-on-select="false"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row align-h="end">
        <b-col
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="storeMarcas"
          >
            Cadastrar
          </b-button>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormInput,
  BModal,
  // BBadge,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormInput,
    BModal,
    // BBadge,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      searchQuery: null,
      editModal: false,
      MarcasID: '',
      fields: [
        { key: 'nome', label: 'nome', class: 'text-center' },
        { key: 'opcoes', label: 'opções', class: 'text-center' },
      ],
      items: [],
      empresaID: null,
      optionsMarcas: [],
      selectedMarca: [],
      load: true,
    }
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getMarcas()
  },
  methods: {
    clearModal() {
      this.selectedMarca = []
    },
    storeMarcas() {
      this.load = true
      const body = {
        empresa_id: this.empresaID,
        marcas_id: this.selectedMarca.map(item => item.id),
      }
      axios.post('api/marca/storeEmpresa', body).then(() => {
        this.$refs.marcasModal.hide()
        this.getMarcas()
        this.$swal({
          title: 'Marca Cadastrada!',
          icon: 'success',
          text: 'Marca Cadastrada!',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(error => {
          this.load = false
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: `Ocorreu um erro durante o cadastro: ${error.response.data.mensagem} `,
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    getMarcas() {
      this.items = []
      axios.get(`api/empresas/marcas/${this.empresaID}`).then(res => {
        this.load = false
        this.items = res.data.dados
      })
    },
    async openModalMarcas() {
      await this.listarMarcas()
      this.$refs.marcasModal.show()
    },
    listarMarcas() {
      this.optionsMarcas = []
      axios.get('api/marca/')
        .then(res => {
          res.data.dados.map(item => {
            this.optionsMarcas.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
  },
}
</script>
